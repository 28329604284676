import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useRef, useState } from "react"

import submitContactForm from "../utils/contact"
import ContactFormFields from "./contact-form-fields"

const RequestDemoSection = ({
  title = "Submit",
  formPlacement = "Home Page",
  staticFields = {},
  showDescription = true,
}) => {
  const contactForm = useRef()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const submitForm = async event => {
    event.preventDefault()
    setIsLoading(true)
    setError("")

    const { success, error } = await submitContactForm(contactForm.current)
    setIsLoading(false)

    if (!success) {
      setError(error)
      return
    }

    contactForm.current.reset()
    setSuccess(true)
  }

  return (
    <div className="container px-4">
      <div className="columns is-desktop">
        <div className="column">
          <div style={{ position: "sticky", top: "1em" }}>
            <h2 className="title is-2 has-text-weight-bold has-text-link">
              {success ? "Thank you!" : "Let's Talk!"}
            </h2>
            {showDescription && (
              <p className="is-size-5">
                {success
                  ? "Your message was sent. Our representative will reach out to you shortly."
                  : "Whether you have a question or would like to schedule a demo we are happy to help."}
              </p>
            )}
          </div>
        </div>
        <div className="column is-1 is-hidden-desktop-only"></div>
        <div className="column">
          <section>
            <div class="contact-form" id="request-a-demo-wrap">
              <form ref={contactForm}>
                <input
                  type="hidden"
                  name="formPlacement"
                  value={formPlacement}
                />
                <ContactFormFields
                  success={success}
                  staticFields={staticFields}
                ></ContactFormFields>

                {success ? (
                  <button
                    className="button is-rounded is-medium is-link"
                    disabled
                  >
                    Thank you!
                  </button>
                ) : isLoading ? (
                  <button
                    className="button is-rounded is-medium is-link"
                    disabled
                  >
                    <FontAwesomeIcon
                      icon={faSpinner}
                      fixedWidth
                      spin
                      size="2x"
                    />
                  </button>
                ) : (
                  <button
                    className="button is-rounded is-medium is-link"
                    onClick={event => submitForm(event)}
                  >
                    {title}
                  </button>
                )}

                <p>{error}</p>
              </form>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default RequestDemoSection
