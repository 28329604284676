import React from "react"

export default ({
  label,
  name,
  options,
  emRef,
  onChangeCallback,
  isDanger = false,
  defaultValue = "",
}) => {
  const selectClass = isDanger ? "select is-danger" : "select"
  return (
    <div class="field">
      <label class="label">{label}</label>
      <div class="control">
        <div className={selectClass}>
          <select
            ref={emRef}
            name={name}
            onChange={
              onChangeCallback ? event => onChangeCallback(event) : null
            }
            defaultValue={defaultValue}
          >
            <option value="none" selected disabled>
              Select an option
            </option>
            {options.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}
