import React from "react"

export default ({ label, name, placeholder, type = "text" }) => (
  <div class="field">
    <label class="label">{label}</label>
    <div class="control">
      <input
        class="input"
        name={name}
        type={type}
        placeholder={placeholder || name}
      />
    </div>
  </div>
)
