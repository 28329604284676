import React, {
  Children,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const ReCaptchaToken = ({ success }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [token, setToken] = useState("")

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(
    async (retries = 10) => {
      if (retries < 0) {
        // console.log("Out of retries!")
        return
      }
      if (success) {
        // console.log("Dialog open, skipping")
        return
      }
      if (!executeRecaptcha) {
        // console.log("Execute recaptcha not yet available")
        return
      }

      try {
        const tkn = await executeRecaptcha()
        // console.log("Set token")
        setToken(tkn)
      } catch (e) {
        setTimeout(() => {
          // console.log("Failed! Remaining retries", retries)
          handleReCaptchaVerify(retries - 1)
        }, 100)
      }
    },
    [executeRecaptcha, success]
  )

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  return (
    <>
      <input type="hidden" value={token} name="g-recaptcha-response"></input>
      <p className="has-text-grey-light is-size-7">
        This site is protected by reCAPTCHA and the Google{" "}
        <a
          className="has-text-grey-light"
          href="https://policies.google.com/privacy"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          className="has-text-grey-light"
          href="https://policies.google.com/terms"
        >
          Terms of Service
        </a>{" "}
        apply.
      </p>
    </>
  )
}

export default ReCaptchaToken
