import csc from "country-state-city"
import React, { useState } from "react"

import { FormInput, FormSelect } from "./form-controls"
import ReCaptchaToken from "./re-captcha-token"

const countries = csc.getAllCountries().map(({ name }) => name)
const statesUS = csc.getStatesOfCountry("231").map(({ name }) => name)
const provincesCA = csc.getStatesOfCountry("38").map(({ name }) => name)

const businessTypes = ["An Agency", "A Brand", "A Publisher", "Other"]

const topics = [
  "Request Demo",
  "Speak to Sales",
  "Speak to PR",
  "Discuss Partnership Opportunities",
  "Get help with my inPowered Account",
  "Other",
]

export default function ContactFormFields({ success, staticFields = {} }) {
  const [showUSStates, setShowUSStates] = useState(true)
  const [showCAProvinces, setShowCAProvinces] = useState(false)
  const countrySelect = React.createRef()

  const mkField = (fieldName, fallback) => {
    return staticFields[fieldName] ? (
      <input type="hidden" name={fieldName} value={staticFields[fieldName]} />
    ) : (
      fallback
    )
  }

  const onCountrySelectChange = () => {
    setShowCAProvinces(countrySelect.current.value === "Canada")
    setShowUSStates(countrySelect.current.value === "United States")
  }

  return (
    <>
      {mkField(
        "topic",
        <FormSelect options={topics} name="topic" label="I'd like to" />
      )}

      {mkField(
        "businessType",
        <FormSelect options={businessTypes} name="businessType" label="I am" />
      )}

      {mkField(
        "name",
        <FormInput name="name" label="Name" placeholder="e.g. John Doe" />
      )}
      {mkField(
        "company",
        <FormInput name="company" label="Company" placeholder="e.g. Apple" />
      )}
      {mkField(
        "email",
        <FormInput
          name="email"
          label="Work Email"
          placeholder="example@email.com"
        />
      )}

      {mkField(
        "country",
        <FormSelect
          options={countries}
          label="Country"
          name="country"
          defaultValue="United States"
          emRef={countrySelect}
          onChangeCallback={() => onCountrySelectChange()}
        />
      )}

      {showUSStates && (
        <FormSelect options={statesUS} label="State" name="state" />
      )}

      {showCAProvinces && (
        <FormSelect options={provincesCA} label="Province" name="state" />
      )}

      {mkField(
        "message",
        <div class="field" style={{ marginTop: "0.75em" }}>
          <label class="label">Message (optional)</label>
          <div class="control">
            <textarea
              name="message"
              class="textarea has-fixed-size"
              placeholder="Add some details here..."
            ></textarea>
          </div>
        </div>
      )}

      <ReCaptchaToken success={success} />
    </>
  )
}
